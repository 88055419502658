.per-page-selector {
  width: 90px;
}
@media print {
.main-menu,
  .paginate-area,
  .content-header-breadcrumb,
  .table tbody tr > td:first-child,
  .table thead tr > th:first-child {
    display: none;
}
.card .m-2 {
    display: none;
}
[dir=ltr] .table tbody tr > td {
    border-right: 1px solid #eff0f0;
}
[dir=rtl] .table tbody tr > td {
    border-left: 1px solid #eff0f0;
}
}